import Api from "@/includes/logic/Api";
import {
  InviteLinkStatReq,
  InviteLinksWithStat, InviteLinkToCreate, InviteLinkToEdit,
  NewInviteLink
} from "@/includes/logic/InviteLinks/types";

async function reqInviteLinks(chat_id: number): Promise<{data: { links: Array<NewInviteLink> }}>  {
  return Api.sendRequest('tg', 'getinvitelinks', { chat_id })
}

async function reqInviteLinksStat(chat_id: number, body: InviteLinkStatReq): Promise<{ data: { links: InviteLinksWithStat } }> {
  return Api.sendRequest('tg', 'getinvitelinksstat', { chat_id, ...body })
}

async function reqDeleteInviteLink(chat_id: number, code: NewInviteLink['code']): Promise<void> {
  return Api.sendRequest('tg', 'deleteinvitelink', { chat_id, code })
}

async function reqEditInviteLink(chat_id: number, link: InviteLinkToEdit): Promise<{ data: { link: NewInviteLink } }> {
  return Api.sendRequest('tg', 'setinvitelink', { chat_id, ...link })
}

async function reqCreateInviteLink(chat_id: number, link: InviteLinkToCreate): Promise<{ data: { link: NewInviteLink } }> {
  return Api.sendRequest('tg', 'createinvitelink', { chat_id, ...link })
}

export {
  reqInviteLinks,
  reqInviteLinksStat,
  reqDeleteInviteLink,
  reqEditInviteLink,
  reqCreateInviteLink
}
