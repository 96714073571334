import {
  reqCreateInviteLink,
  reqDeleteInviteLink,
  reqEditInviteLink,
  reqInviteLinks,
  reqInviteLinksStat
} from "@/includes/Api/InviteLinks.api";
import {
  InviteLinkStatReq,
  InviteLinksWithStat, InviteLinkToCreate, InviteLinkToEdit, InviteLinkWithStat,
  LocalInviteLink,
  NewInviteLink,
} from "@/includes/logic/InviteLinks/types";
import { errorNotification } from "@/includes/NotificationService";

import { seriesByMetricInList } from "piramis-base-components/src/shared/utils/seriesByMetricInList";
import { fromToTicks } from "piramis-base-components/src/shared/utils/fromToTicks";
import { SelectOption } from "piramis-base-components/src/logic/types";

import { cloneDeep } from "lodash";
import VueI18n from "vue-i18n";
import moment from "moment";

async function getInviteLinks(chat_id: number) {
  return reqInviteLinks(chat_id)
      .then(res => res.data.links)
      .catch((e) => {
        errorNotification(e)

        return []
      })
}

async function getInviteLinksStat(chat_id: number, body: InviteLinkStatReq) {
  return reqInviteLinksStat(chat_id, body)
      .then(res =>  res.data.links)
      .catch((e) => {
        errorNotification(e)

        return []
      })
}

async function deleteInviteLink(chatId: number, code: NewInviteLink['code']) {
  return reqDeleteInviteLink(chatId, code)
      .then(() => true)
      .catch(errorNotification)

}

async function editInviteLink(chatId: number, link: InviteLinkToEdit) {
  return reqEditInviteLink(chatId, link)
      .then((res) => res.data.link)
      .catch(errorNotification)
}

async function createInviteLink(chatId: number, link: InviteLinkToCreate) {
  return reqCreateInviteLink(chatId, link)
      .then((res) => res.data.link)
      .catch(errorNotification)
}

function isLinkActive(stat: InviteLinkWithStat['stat']) {
  return (stat ?? []).some(i => i.enter_unique_first_users > 0)
}

function linkStatistics(linkCode: NewInviteLink['code'], statistics: InviteLinksWithStat) {
  return statistics.find(s => s.link.code === linkCode)
}

function prepareInviteLinks(links: Array<NewInviteLink>, statistics: InviteLinksWithStat) {
  const linksCopy = cloneDeep(links)

  return linksCopy.map(link => {
    const stat = linkStatistics(link.code, statistics)?.stat

    if (Array.isArray(stat) && isLinkActive(stat)) {
      return {
          link,
          stat
      }
    } else {
      return {
        link,
      }
    }
  })
}

async function prepareSimpleSeriesForLinks(links: InviteLinksWithStat, i18n: VueI18n) {
  const fromTo = fromToTicks(
      moment().add(-7, 'd').format('YYYY-MM-DD 00:00:00'),
      moment().format('YYYY-MM-DD 00:00:00'),
      {
        format: 'YYYY-MM-DD 00:00:00'
      }
  )

  const linksPromises = links.map<Promise<LocalInviteLink>>(async (link) => {
    if (link.stat) {
      const series = await seriesByMetricInList(i18n, link.stat, [ 'enter_unique_first_users', 'kick_unique_users', 'leave_unique_users' ], fromTo)
          .calcSeries()
          .then((res) => res)

      return {
        ...link,
        chart: {
          series
        }
      }
    } else {
      return {
        ...link,
        chart: {
          series: [
            {
              name: i18n.t('invite_link_enter').toString(),
              data: [ link.link.enter ],
              type: 'bar'
            },
            {
              name: i18n.t('invite_link_kick').toString(),
              data: [ link.link.kick ],
              type: 'bar'
            },
            {
              name: i18n.t('invite_link_leave').toString(),
              data: [ link.link.leave ],
              type: 'bar'
            }
          ]
        }
      }
    }
  })

  return Promise.all(linksPromises)
}

function removeLinkFromList(links: Array<LocalInviteLink>, code: NewInviteLink['code']) {
  return links.filter(l => l.link.code !== code)
}

function updateLink(links: Array<LocalInviteLink>, link: NewInviteLink) {
  const l = links.find(l => l.link.code === link.code)

  if (l) {
    l.link = link
  }

  return links
}

function findOne<T extends InviteLinkWithStat>(links: Array<T>, code: NewInviteLink['code']) {
  return links.find(l => l.link.code === code)?.link
}

function selectOptionsFromLinks(links: Array<InviteLinkWithStat>, i18n: VueI18n): Array<SelectOption> {
  return links.map(l => {
    const { link, title, code } = l.link

    if (link === '' && title === '') {
      return {
        label: i18n.t('link_statistics_no_link').toString(),
        value: null
      }
    } else {
      return {
        label: title,
        value: code
      }
    }
  })
}

export {
  getInviteLinks,
  getInviteLinksStat,
  deleteInviteLink,
  removeLinkFromList,
  editInviteLink,
  createInviteLink,
  prepareInviteLinks,
  prepareSimpleSeriesForLinks,
  updateLink,
  findOne,
  selectOptionsFromLinks
}
